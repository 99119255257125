<template>
  <!-- 提问题 -->
  <a-modal
    :title="$t('bbs.addpost.ask')"
    width="100%"
    wrapClassName="full-modal"
    :keyboard="false"
    :closable="false"
    v-model:visible="visible"
  >
    <div class="full-modal-close" @click="handleCancel">
      <CloseOutlined class="full-modal-close-x" />
    </div>
    <div class="full-modal-content">
      <div class="post-form">
        <a-form :model="formData" layout="vertical" ref="formRef">
          <!-- 圈子 -->
          <a-form-item
            :label="$t('bbs.addpost.circle')"
            name="groupId"
            :rules="{
              required: true,
              type: 'number',
              message: $t('bbs.addpost.please_select_circle'),
              trigger: 'change',
            }"
          >
            <!-- 请选择圈子 -->
            <a-select
              style="width: 480px"
              v-model:value="formData.groupId"
              :placeholder="$t('bbs.addpost.please_select_circle')"
              showSearch
              optionFilterProp="label"
              @change="groupChange"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            >
              <a-select-option
                :value="item.groupId"
                v-for="item in groupList"
                :key="item.groupId"
                :label="item.groupName"
              >
                {{ item.groupName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <!-- 标题 -->
          <a-form-item
            :label="$t('bbs.addpost.title')"
            name="title"
            :rules="[
              {
                required: true,
                message: $t('bbs.addpost.please_enter_title'),
                // 请输入标题
                trigger: 'change',
              },
              {
                min: communityConfig.qaTitleMin,
                message: $t('bbs.addpost.title_less_tip', [
                  communityConfig.qaTitleMin,
                ]),
                // 标题不得少于
                trigger: 'change',
              },
            ]"
          >
            <a-textarea
              :rows="4"
              v-model:value="formData.title"
              :placeholder="$t('bbs.addpost.please_enter_title')"
              showCount
              :maxlength="communityConfig.qaTitleMax"
            />
            <!-- 请输入内容 -->
          </a-form-item>
          <a-form-item
            label="内容"
            name="content"
            :rules="[
              {
                required: true,
                message: $t('CM_PleaseEnterContent'),
                // 请输入内容
                trigger: 'change',
              },
              {
                min: communityConfig.qaTextMin,
                message: $t('bbs.addpost.content_less_tip', [
                  communityConfig.qaTextMin,
                ]),
                // 内容不得少于
                trigger: 'change',
              },
            ]"
          >
            <a-textarea
              :rows="8"
              v-model:value="formData.content"
              :placeholder="$t('CM_PleaseEnterContent')"
              showCount
              :maxlength="communityConfig.qaTextMax"
            />
            <!-- 请输入内容 -->
            <div class="tools">
              <div
                class="tools-item"
                @click="$refs.modalMediaRef.openModal(formData)"
              >
                <!-- 图片 -->
                <PictureOutlined />{{ $t("LB_Picture")
                }}{{
                  formData.coverImgs.length
                    ? `(${formData.coverImgs.length})`
                    : ""
                }}/{{ $t("Pub_Video")
                }}{{ formData.coverVideos.length ? "(1)" : "" }}
                <!-- 视频 -->
              </div>
              <div
                class="tools-item"
                @click="$refs.modalLinkRef.openModal(formData.links)"
              >
                <LinkOutlined />{{ $t("bbs.addpost.link")
                }}{{ formData.links ? "(1)" : "" }}
                <!-- 链接 -->
              </div>
              <div
                class="tools-item"
                @click="$refs.modalFileRef.openModal(formData)"
              >
                <!-- 附件 -->
                <PaperClipOutlined />{{ $t("CM_LB_AttachmentFile")
                }}{{
                  formData.files.length ? `(${formData.files.length})` : ""
                }}
              </div>
            </div>
          </a-form-item>
          <!-- 话题 -->
          <a-form-item :label="$t('bbs.addpost.topic')">
            <a-dropdown
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              :trigger="['click']"
              placement="topLeft"
            >
              <a-input
                style="width: 480px"
                v-model:value="topicVal"
                :placeholder="$t('bbs.addpost.select_topic')"
                @change="topicSearch"
                @pressEnter="communityConfig.isTopic === 1 && topicAdd('enter')"
              />
              <!-- 选择话题 -->
              <template #overlay>
                <a-menu style="max-height: 200px; overflow-y: auto">
                  <a-spin :spinning="topicLoading">
                    <a-menu-item
                      v-for="item in topicList"
                      :key="item.topicId"
                      @click="topicAdd('select', item)"
                    >
                      {{ item.topicName }}
                    </a-menu-item>
                    <a-empty v-if="!topicList.length" />
                  </a-spin>
                </a-menu>
              </template>
            </a-dropdown>
            <span class="tips">
              <template v-if="!formData.topicStrs.length"
                >{{ $t("bbs.addpost.select_topic")
                }}{{
                  communityConfig.isTopic === 1
                    ? $t("bbs.addpost.click_enter_create_topic")
                    : ""
                }}</template
              >
              <!-- ，或点击回车新建话题 -->
              <template v-else
                >{{ $t("bbs.addpost.choose_up_topics", [5])
                }}{{ formData.topicStrs.length }}/5）</template
              >
              <!-- 最多选择5个话题（ -->
            </span>
            <div class="tags">
              <a-tag
                color="rgba(0, 0, 0, 0.06)"
                v-for="(item, index) in formData.topicStrs"
                :key="index"
                >#{{ item }}# <CloseOutlined @click="topicDel(index)" />
              </a-tag>
            </div>
          </a-form-item>
          <a-form-item v-if="communityConfig.isAtAll === 1">
            <template #label>
              <!-- 提醒谁看 -->
              {{ $t("bbs.remind_who_watch")
              }}<span class="tips" style="padding-left: 0"
                >（{{ $t("bbs.remind_who_watch_tip") }}）</span
              >
              <!-- （选择的人会收到消息提醒） -->
            </template>
            <a-dropdown
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              :trigger="['click']"
              placement="topLeft"
            >
              <a-input
                style="width: 480px"
                v-model:value="memberVal"
                :placeholder="$t('bbs.addpost.choose_person')"
                @change="memberSearch"
              />
              <!-- 选择人员 -->
              <template #overlay>
                <a-menu style="max-height: 200px; overflow-y: auto">
                  <a-spin :spinning="memberLoading">
                    <div style="min-height: 32px">
                      <a-menu-item
                        v-for="item in memberList"
                        :key="item.userId"
                        @click="memberAdd(item)"
                      >
                        <OpenData type="userName" :openid="item.realName" />
                        ({{ item.account }})
                      </a-menu-item>
                      <a-empty
                        v-if="!memberList.length && !memberLoading && memberVal"
                      />
                    </div>
                  </a-spin>
                </a-menu>
              </template>
            </a-dropdown>
            <span class="tips">
              <template v-if="!formData.remindMembers.length">{{
                $t("bbs.addpost.invite_up_to_nth_people", [10])
              }}</template>
              <!-- 最多邀请10位 -->
              <template v-else
                >{{ $t("XB_Personnel") }}（{{
                  formData.remindMembers.length
                }}/10）</template
              >
              <!-- 人员（ -->
            </span>
            <div class="tags">
              <a-tag
                color="rgba(0, 0, 0, 0.06)"
                v-for="(item, index) in formData.remindMembers"
                :key="item.userId"
              >
                <OpenData type="userName" :openid="item.realName" />
                ({{ item.account }})
                <CloseOutlined @click="memberDel(index)" />
              </a-tag>
            </div>
          </a-form-item>
          <!-- 悬赏积分 -->
          <a-form-item
            :label="$t('bbs.addpost.reward_points')"
            v-if="useIntegral"
          >
            <a-input-number
              v-model:value="formData.rewardIntegral"
              :min="0"
              :max="integralSum"
              :precision="0"
              :disabled="formData.subjectId"
            />
            <span class="tips"
              >{{ $t("bbs.addpost.available_points") }}:<i>{{ integralSum }}</i>
            </span>
            <!-- 可用积分 -->
          </a-form-item>
        </a-form>
      </div>
    </div>
    <template #footer>
      <div class="custom-footer">
        <div>
          <a-checkbox
            v-model:checked="formData.isAnonymous"
            v-if="seleGroup.isPublishAnonymous === 1"
            >{{ $t("bbs.addpost.post_anonymously") }}</a-checkbox
          >
          <!-- 匿名发帖 -->
        </div>
        <div class="btns">
          <a-button @click="handleCancel">{{ $t("CM_Cancel") }}</a-button>
          <!-- 取消 -->
          <a-button type="primary" :loading="loading" @click="handleOk">{{
            $t("bbs.publish")
          }}</a-button>
          <!-- 发布 -->
        </div>
      </div>
    </template>
  </a-modal>
  <modalMedia ref="modalMediaRef" @handleOk="addMedia" />
  <modalLink ref="modalLinkRef" @handleOk="addLink" />
  <modalFile ref="modalFileRef" @handleOk="addFile" />
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, ref, getCurrentInstance, createVNode } from "vue";
import ls from "@/utils/local-storage";

import { debounce } from "lodash";

import { getStudyIntegralList } from "@/api/mine";
import {
  getGroupList,
  getTopicList,
  postTopic,
  getAllMember,
  postSubject,
} from "@/api/bbs";
import { wxworkSearch, dingSearch } from "@/api/other";

import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";

import OpenData from "@/components/OpenData.vue";
import modalMedia from "./modalMedia.vue";
import modalLink from "./modalLink.vue";
import modalFile from "./modalFile.vue";
export default {
  name: "modalQuestion",
  components: {
    OpenData,
    modalMedia,
    modalLink,
    modalFile,
  },
  props: {
    communityConfig: {
      type: Object,
      default: () => {
        return {};
      },
    },
    groupId: {
      type: Number,
      default: 0,
    },
    topicId: {
      type: Number,
      default: 0,
    },
    topicStr: {
      type: String,
      default: "",
    },
  },
  emits: ["handleOk"],
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const state = reactive({
      platform: 0,
      useInternalWxWork: 0,
      useIntegral: false,
      visible: false,
      loading: false,
      groupList: [],
      groupJson: {},
      seleGroup: {},
      topicList: [],
      topicVal: "",
      topicLoading: false,
      memberList: [],
      initMemberList: [],
      memberVal: "",
      memberLoading: false,
      integralSum: 0,
      formData: {
        subjectType: 2,
        groupId: props.groupId || "",
        title: "",
        content: "",
        rewardIntegral: 0,
        coverImgs: [],
        coverVideos: [],
        links: "",
        isDown: true,
        files: [],
        topicIds: props.topicId ? [props.topicId] : [],
        topicStrs: props.topicStr ? [props.topicStr] : [],
        remindUids: [],
        remindMembers: [],
        isAnonymous: false,
      },
    });

    const openModal = (data) => {
      state.platform = ls.get("platformConfig").platform;
      state.useInternalWxWork = ls.get("companyInfo").useInternalWxWork;
      let menu = ls.get("companyInfo").menu;
      state.useIntegral = menu.indexOf("integral") !== -1;
      getGroupList({ type: 1 }).then((res) => {
        state.groupList = res.data.list || [];
        state.groupList.map((item) => {
          state.groupJson[item.groupId] = item;
        });
        if (data) {
          groupChange(data.groupId, "no-reset");
        } else if (props.groupId) {
          groupChange(props.groupId, "no-reset");
        }
      });
      getTopicList({ page: 1, pageSize: 30 }).then((res) => {
        state.topicList = res.data.list || [];
      });
      getAllMember({ page: 1, pageSize: 10 }).then((res) => {
        state.memberList = res.data.list || [];
        state.initMemberList = res.data.list || [];
      });
      getStudyIntegralList({ page: 1 }).then((res) => {
        state.integralSum = res.data.usablesum || 0;
      });
      state.visible = true;
      state.topicVal = "";
      state.memberVal = "";
      if (data) {
        state.formData = {
          subjectId: data.subjectId,
          subjectType: 2,
          groupId: data.groupId,
          title: data.title,
          content: data.content,
          rewardIntegral: data.rewardIntegral,
          coverImgs: data.coverImgss
            ? data.coverImgss.map((item) => {
                return {
                  md5: Math.random() * Math.random(),
                  name: item,
                  file: item,
                  exist: true,
                  url: item,
                };
              })
            : [],
          coverVideos: data.coverVideoss ? data.coverVideoss : [],
          links: data.links,
          isDown: data.isDown === 1,
          files: data.attachmentList
            ? data.attachmentList.map((item) => {
                return {
                  md5: item.md5,
                  name: item.fileName,
                  file: item.filePath,
                  exist: true,
                  url: item.filePath,
                };
              })
            : [],
          topicIds: data.topicIds
            ? data.topicIds.split(",").map((n) => Number(n))
            : [],
          topicStrs: data.topics
            ? data.topics.map((item) => item.topicName)
            : [],
          remindUids: data.remindUids
            ? data.remindUids.split(",").map((n) => Number(n))
            : [],
          remindMembers: data.remindUsers || [],
          isAnonymous: data.isAnonymous === 1,
        };
      } else {
        state.formData = {
          subjectType: 2,
          groupId: props.groupId || "",
          title: "",
          content: "",
          rewardIntegral: 0,
          coverImgs: [],
          coverVideos: [],
          links: "",
          isDown: true,
          files: [],
          topicIds: props.topicId ? [props.topicId] : [],
          topicStrs: props.topicStr ? [props.topicStr] : [],
          remindUids: [],
          remindMembers: [],
          isAnonymous: false,
        };
      }
    };

    const groupChange = (val, type = null) => {
      state.seleGroup = state.groupJson[val];
      !type && (state.formData.isAnonymous = false);
    };

    const addMedia = ({ pics, videos }) => {
      state.formData.coverImgs = pics;
      state.formData.coverVideos = videos;
    };

    const addLink = (link) => {
      state.formData.links = link;
    };

    const addFile = ({ isDown, files }) => {
      state.formData.isDown = isDown;
      state.formData.files = files;
    };

    const topicSearch = debounce(() => {
      state.topicLoading = true;
      getTopicList({ page: 1, keyword: state.topicVal }).then((res) => {
        state.topicLoading = false;
        state.topicList = res.data.list || [];
      });
    }, 500);

    const topicAdd = (type, data) => {
      if (state.formData.topicIds.length >= 5) {
        proxy.$message.warn($t("bbs.addpost.choose_up_topics", [5]));
        // 最多选择5个话题
        return false;
      }
      if (type === "select") {
        if (state.formData.topicIds.includes(data.topicId)) {
          proxy.$message.warn($t("bbs.addpost.this_topic_is_selected"));
          // 已选择此话题
          return false;
        }
        state.formData.topicStrs.push(data.topicName);
        state.formData.topicIds.push(data.topicId);
        state.topicVal = "";
        topicSearch();
      } else if (type === "enter") {
        if (state.topicVal.trim().length) {
          if (state.topicVal.trim().length <= 1) {
            proxy.$message.warn($t("bbs.addpost.less_word_topic", [2]));
            // 话题不得少于2个字
            return false;
          }
          postTopic({
            topicName: state.topicVal,
          }).then((res) => {
            if (res.ret === 0) {
              state.formData.topicStrs.push(state.topicVal);
              state.formData.topicIds.push(res.data);
              state.topicVal = "";
              topicSearch();
            }
          });
        }
      }
    };

    const topicDel = (index) => {
      state.formData.topicStrs.splice(index, 1);
      state.formData.topicIds.splice(index, 1);
    };

    const memberSearch = debounce(() => {
      if (state.memberVal.trim() == "") {
        state.memberList = JSON.parse(JSON.stringify(state.initMemberList));
        return false;
      }
      state.memberLoading = true;
      if (state.platform == 26 || state.platform == 31) {
        let searchAjax = wxworkSearch;
        if (state.platform == 31) searchAjax = dingSearch;
        searchAjax(state.memberVal).then((res) => {
          getAllMember({
            page: 1,
            pageSize: 10,
            keyword: state.memberVal,
            accounts: res.data.users || [],
          }).then((res) => {
            state.memberLoading = false;
            state.memberList = res.data.list || [];
          });
        });
      } else {
        getAllMember({ page: 1, pageSize: 10, keyword: state.memberVal }).then(
          (res) => {
            state.memberLoading = false;
            state.memberList = res.data.list || [];
          }
        );
      }
    }, 500);

    const memberAdd = (data) => {
      if (state.formData.remindUids.length >= 10) {
        proxy.$message.warn($t("bbs.select_most_people", [10]));
        // 最多选择10个人员
        return false;
      }
      if (state.formData.remindUids.includes(data.userId)) {
        proxy.$message.warn($t("bbs.person_is_select"));
        // 已选择此人员
        return false;
      }
      state.formData.remindMembers.push(data);
      state.formData.remindUids.push(data.userId);
      state.memberVal = "";
    };

    const memberDel = (index) => {
      state.formData.remindMembers.splice(index, 1);
      state.formData.remindUids.splice(index, 1);
    };

    const formRef = ref(null);
    const handleOk = () => {
      formRef.value.validate().then(() => {
        state.loading = true;
        let formData = JSON.parse(JSON.stringify(state.formData));
        formData.isAnonymous = formData.isAnonymous ? 1 : 2;
        formData.isDown = formData.isDown ? 1 : 2;
        formData.coverImgs = formData.coverImgs.map((item) => item.file);
        formData.coverVideos = formData.coverVideos.map((item) => {
          return {
            file: item.exist ? item.md5 : item.file,
            name: item.name,
          };
        });
        formData.files = formData.files.map((item) => {
          return {
            file: item.exist ? item.md5 : item.file,
            name: item.name,
          };
        });
        formData.status = 1;
        postSubject(formData).then((res) => {
          const msg = formData.subjectId
            ? $t("CM_Success")
            : $t("bbs.addpost.published_successfully");
          state.loading = false;
          if (res.ret === 0) {
            proxy.$message.success(`${msg}`);
            emit("handleOk");
            state.visible = false;
          }
        });
      });
    };

    const handleCancel = () => {
      let d = state.formData;
      let tipsFlag =
        (d.groupId === "" || d.groupId === props.groupId) &&
        d.title === "" &&
        d.content === "" &&
        d.rewardIntegral === 0 &&
        d.coverImgs.length === 0 &&
        d.coverVideos.length === 0 &&
        d.links === "" &&
        d.files.length === 0 &&
        d.topicIds.length === 0 &&
        d.remindUids.length === 0;
      if (!tipsFlag) {
        Modal.confirm({
          title: () => $t("CM_Tip"),
          // 提示
          icon: () => createVNode(ExclamationCircleOutlined),
          content: $t("bbs.confirm_cancel_tip"),
          // 当前操作尚未保存，是否确认取消？
          onOk() {
            formRef.value.resetFields();
            state.visible = false;
          },
        });
      } else {
        formRef.value.resetFields();
        state.visible = false;
      }
    };

    return {
      ...toRefs(state),
      openModal,
      groupChange,
      addMedia,
      addLink,
      addFile,
      topicSearch,
      topicAdd,
      topicDel,
      memberSearch,
      memberAdd,
      memberDel,
      formRef,
      handleOk,
      handleCancel,
    };
  },
};
</script>

<style lang="less" scoped>
.custom-footer {
  .mixinWrap(880px);
  .mixinFlex(space-between; center);
}
.post-form {
  .mixinWrap(880px);
  .tools {
    .mixinFlex();
    &-item {
      .mixinFlex(flex-start; center);
      font-size: 14px;
      color: #666;
      margin-right: 40px;
      transition: color 0.3s;
      cursor: pointer;
      .anticon {
        margin-right: 5px;
      }
      &:hover {
        color: @color-theme;
      }
    }
  }
  .tips {
    font-size: 14px;
    line-height: 32px;
    color: #c4c4c4;
    padding-left: 28px;
    i {
      font-style: normal;
      color: @color-theme;
    }
  }
  .tags {
    margin-top: 8px;
    ::v-deep(.ant-tag) {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.65);
      .anticon-close {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
}
</style>
