<template>
  <!-- 发文章 -->
  <a-modal
    :title="$t('bbs.addpost.article')"
    width="100%"
    wrapClassName="full-modal"
    :keyboard="false"
    :closable="false"
    v-model:visible="visible"
  >
    <div class="full-modal-close" @click="handleCancel">
      <CloseOutlined class="full-modal-close-x" />
    </div>
    <div class="full-modal-content">
      <div class="post-form">
        <a-form :model="formData" layout="vertical" ref="formRef">
          <div class="flex">
            <div style="width: 478px">
              <!-- 圈子 -->
              <a-form-item
                :label="$t('bbs.addpost.circle')"
                name="groupId"
                :rules="{
                  required: true,
                  type: 'number',
                  message: $t('bbs.addpost.please_select_circle'),
                  trigger: 'change',
                }"
              >
                <!-- 请选择圈子 -->
                <a-select
                  v-model:value="formData.groupId"
                  :placeholder="$t('bbs.addpost.please_select_circle')"
                  showSearch
                  optionFilterProp="label"
                  @change="groupChange"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option
                    :value="item.groupId"
                    v-for="item in groupList"
                    :key="item.groupId"
                    :label="item.groupName"
                  >
                    {{ item.groupName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <!-- 标题 -->
              <a-form-item
                :label="$t('bbs.addpost.title')"
                name="title"
                :rules="[
                  {
                    required: true,
                    message: $t('bbs.addpost.please_enter_title'),
                    // 请输入标题
                    trigger: 'change',
                  },
                  {
                    min: communityConfig.articleTitleMin,
                    message: $t('bbs.addpost.title_less_tip', [
                      communityConfig.articleTitleMin,
                    ]),
                    // 标题不得少于
                    trigger: 'change',
                  },
                ]"
              >
                <a-textarea
                  :rows="4"
                  v-model:value="formData.title"
                  :placeholder="$t('bbs.addpost.please_enter_title')"
                  showCount
                  :maxlength="communityConfig.articleTitleMax"
                />
                <!-- 请输入标题 -->
              </a-form-item>
            </div>
            <div>
              <div class="cover">
                <img :src="upCdnCover" v-if="upCdnCover" />
                <div class="text" v-else>
                  {{ $t("bbs.addpost.upload_cover") }}
                </div>
                <!-- 上传封面 -->
                <input
                  class="up-input"
                  ref="uploadInput"
                  type="file"
                  accept="image/jpg, image/jpeg, image/png"
                  @change="selectFile"
                />
              </div>
              <p class="cover-tips">{{ $t("bbs.addpost.upload_cover_tip") }}</p>
              <!-- 建议尺寸：260*150；大小不超过1M -->
            </div>
          </div>
          <!-- 正文 -->
          <a-form-item
            :label="$t('bbs.addpost.text')"
            name="contentText"
            :rules="[
              {
                required: true,
                message: $t('bbs.addpost.please_enter_text'),
                // 请输入正文
                trigger: 'change',
              },
              {
                min: communityConfig.articleTextMin,
                message: $t('bbs.addpost.text_less_tip', [
                  communityConfig.articleTextMin,
                ]),
                // 正文不得少于
                trigger: 'change',
              },
              {
                max: communityConfig.articleTextMax,
                message: $t('bbs.addpost.text_more_tip', [
                  communityConfig.articleTextMax,
                ]),
                // 正文不得大于
                trigger: 'change',
              },
            ]"
          >
            <wangeditor
              v-if="visible"
              :placeholder="$t('bbs.addpost.please_enter_text')"
              v-on:getWangEditorValue="getWangEditorValue"
              v-on:getWangEditorText="getWangEditorText"
              :editorValue="formData.content"
            />
            <!-- 请输入正文 -->
            <div class="editor-tips">
              <div class="editor-tips-num">
                <span
                  :class="{
                    red:
                      formData.contentText.length >
                      communityConfig.articleTextMax,
                  }"
                  >{{ formData.contentText.length }}</span
                >/{{ communityConfig.articleTextMax }}
              </div>
            </div>
          </a-form-item>
          <!-- 话题 -->
          <a-form-item :label="$t('bbs.addpost.topic')">
            <a-dropdown
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              :trigger="['click']"
              placement="topLeft"
            >
              <a-input
                style="width: 480px"
                v-model:value="topicVal"
                :placeholder="$t('bbs.addpost.select_topic')"
                @change="topicSearch"
                @pressEnter="communityConfig.isTopic === 1 && topicAdd('enter')"
              />
              <!-- 选择话题 -->
              <template #overlay>
                <a-menu style="max-height: 200px; overflow-y: auto">
                  <a-spin :spinning="topicLoading">
                    <a-menu-item
                      v-for="item in topicList"
                      :key="item.topicId"
                      @click="topicAdd('select', item)"
                    >
                      {{ item.topicName }}
                    </a-menu-item>
                    <a-empty v-if="!topicList.length" />
                  </a-spin>
                </a-menu>
              </template>
            </a-dropdown>
            <span class="tips">
              <template v-if="!formData.topicStrs.length"
                >{{ $t("bbs.addpost.select_topic")
                }}{{
                  communityConfig.isTopic === 1
                    ? $t("bbs.addpost.click_enter_create_topic")
                    : ""
                }}</template
              >
              <!-- ，或点击回车新建话题 -->
              <template v-else
                >{{ $t("bbs.addpost.choose_up_topics", [5])
                }}{{ formData.topicStrs.length }}/5）</template
              >
              <!-- 最多选择5个话题（ -->
            </span>
            <div class="tags">
              <a-tag
                color="rgba(0, 0, 0, 0.06)"
                v-for="(item, index) in formData.topicStrs"
                :key="index"
                >#{{ item }}# <CloseOutlined @click="topicDel(index)" />
              </a-tag>
            </div>
          </a-form-item>
          <a-form-item v-if="communityConfig.isAtAll === 1">
            <template #label>
              <!-- 提醒谁看 -->
              {{ $t("bbs.remind_who_watch")
              }}<span class="tips" style="padding-left: 0"
                >（{{ $t("bbs.remind_who_watch_tip") }}）</span
              >
              <!-- （选择的人会收到消息提醒） -->
            </template>
            <a-dropdown
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              :trigger="['click']"
              placement="topLeft"
            >
              <a-input
                style="width: 480px"
                v-model:value="memberVal"
                :placeholder="$t('bbs.addpost.choose_person')"
                @change="memberSearch"
              />
              <template #overlay>
                <a-menu style="max-height: 200px; overflow-y: auto">
                  <a-spin :spinning="memberLoading">
                    <div style="min-height: 32px">
                      <a-menu-item
                        v-for="item in memberList"
                        :key="item.userId"
                        @click="memberAdd(item)"
                      >
                        <OpenData type="userName" :openid="item.realName" />
                        ({{ item.account }})
                      </a-menu-item>
                      <a-empty
                        v-if="!memberList.length && !memberLoading && memberVal"
                      />
                    </div>
                  </a-spin>
                </a-menu>
              </template>
            </a-dropdown>
            <span class="tips">
              <template v-if="!formData.remindMembers.length">{{
                $t("bbs.addpost.invite_up_to_nth_people", [10])
              }}</template>
              <!-- 最多邀请10位 -->
              <template v-else
                >{{ $t("XB_Personnel") }}（{{
                  formData.remindMembers.length
                }}/10）</template
              >
              <!-- 人员（ -->
            </span>
            <div class="tags">
              <a-tag
                color="rgba(0, 0, 0, 0.06)"
                v-for="(item, index) in formData.remindMembers"
                :key="item.userId"
              >
                <OpenData type="userName" :openid="item.realName" />
                ({{ item.account }})
                <CloseOutlined @click="memberDel(index)" />
              </a-tag>
            </div>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <template #footer>
      <div class="custom-footer">
        <div>
          <a-checkbox
            v-model:checked="formData.isAnonymous"
            v-if="seleGroup.isPublishAnonymous === 1"
            >{{ $t("bbs.addpost.post_anonymously") }}</a-checkbox
          >
          <!-- 匿名发帖 -->
        </div>
        <div class="btns">
          <a-button @click="handleCancel">{{ $t("CM_Cancel") }}</a-button>
          <a-button :loading="loading" @click="handleOk(5)">{{
            $t("bbs.addpost.save_draft")
          }}</a-button>
          <!-- 存草稿 -->
          <a-button type="primary" :loading="loading" @click="handleOk(1)">{{
            $t("bbs.publish")
          }}</a-button>
        </div>
      </div>
    </template>
  </a-modal>
  <cropper
    :width="624"
    :height="360"
    ref="cropperRef"
    @cropper:url="upImgEnd"
  />
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, ref, getCurrentInstance, createVNode } from "vue";
import ls from "@/utils/local-storage";

import { getCdnUrl } from "@/utils/tools";
import { debounce } from "lodash";

import {
  getGroupList,
  getTopicList,
  postTopic,
  getAllMember,
  postSubject,
} from "@/api/bbs";
import { wxworkSearch, dingSearch } from "@/api/other";

import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";

import OpenData from "@/components/OpenData.vue";
import wangeditor from "@/components/wangeditor/index.vue";
import cropper from "@/components/cropper.vue";
export default {
  name: "modalArticle",
  components: {
    OpenData,
    wangeditor,
    cropper,
  },
  props: {
    communityConfig: {
      type: Object,
      default: () => {
        return {};
      },
    },
    groupId: {
      type: Number,
      default: 0,
    },
    topicId: {
      type: Number,
      default: 0,
    },
    topicStr: {
      type: String,
      default: "",
    },
  },
  emits: ["handleOk"],
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const formRef = ref(null);
    const state = reactive({
      platform: 0,
      useInternalWxWork: 0,
      visible: false,
      loading: false,
      groupList: [],
      groupJson: {},
      seleGroup: {},
      topicList: [],
      topicVal: "",
      topicLoading: false,
      memberList: [],
      initMemberList: [],
      memberVal: "",
      memberLoading: false,
      upCdnCover: "",
      formData: {
        subjectType: 3,
        groupId: props.groupId || "",
        title: "",
        content: "",
        contentText: "",
        coverImgs: [],
        topicIds: props.topicId ? [props.topicId] : [],
        topicStrs: props.topicStr ? [props.topicStr] : [],
        remindUids: [],
        remindMembers: [],
        isAnonymous: false,
      },
    });

    const openModal = (data) => {
      state.platform = ls.get("platformConfig").platform;
      state.useInternalWxWork = ls.get("companyInfo").useInternalWxWork;
      getGroupList({ type: 1 }).then((res) => {
        state.groupList = res.data.list || [];
        state.groupList.map((item) => {
          state.groupJson[item.groupId] = item;
        });
        if (data) {
          groupChange(data.groupId, "no-reset");
        } else if (props.groupId) {
          groupChange(props.groupId, "no-reset");
        }
      });
      getTopicList({ page: 1, pageSize: 30 }).then((res) => {
        state.topicList = res.data.list || [];
      });
      getAllMember({ page: 1, pageSize: 10 }).then((res) => {
        state.memberList = res.data.list || [];
        state.initMemberList = res.data.list || [];
      });
      state.visible = true;
      state.topicVal = "";
      state.memberVal = "";
      if (data) {
        state.upCdnCover = data.coverImgss ? data.coverImgss[0] : "";
        state.formData = {
          subjectId: data.subjectId,
          subjectType: 3,
          groupId: data.groupId,
          title: data.title,
          content: data.content,
          contentText: "",
          coverImgs: data.coverImgss || [],
          topicIds: data.topicIds
            ? data.topicIds.split(",").map((n) => Number(n))
            : [],
          topicStrs: data.topics
            ? data.topics.map((item) => item.topicName)
            : [],
          remindUids: data.remindUids
            ? data.remindUids.split(",").map((n) => Number(n))
            : [],
          remindMembers: data.remindUsers || [],
          isAnonymous: data.isAnonymous === 1,
        };
      } else {
        state.upCdnCover = "";
        state.formData = {
          subjectType: 3,
          groupId: props.groupId || "",
          title: "",
          content: "",
          contentText: "",
          coverImgs: [],
          topicIds: props.topicId ? [props.topicId] : [],
          topicStrs: props.topicStr ? [props.topicStr] : [],
          remindUids: [],
          remindMembers: [],
          isAnonymous: false,
        };
      }
    };

    const groupChange = (val, type = null) => {
      state.seleGroup = state.groupJson[val];
      !type && (state.formData.isAnonymous = false);
    };

    const uploadInput = ref(null);
    const cropperRef = ref(null);
    const selectFile = (e) => {
      const target = e.target;
      const { files } = target;
      if (!files) return;
      const file = files[0];
      if (file.size / 1024 / 1024 > 1) {
        proxy.$message.warn("文件大小不能大于1M");
        uploadInput.value.value = "";
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cropperRef.value.showDialog(String(reader.result));
        if (!uploadInput.value) return;
        uploadInput.value.value = "";
      };
    };

    const upImgEnd = ({ exist, file }) => {
      state.upCdnCover = exist
        ? process.env.VUE_APP_RES_URL + file
        : getCdnUrl(file, "temp");
      state.formData.coverImgs = [file];
    };

    const getWangEditorValue = (str) => {
      state.formData.content = str;
    };

    let editerFirstRender = true;
    const getWangEditorText = (str) => {
      state.formData.contentText = str;
      if (
        str.length >= props.communityConfig.articleTextMin &&
        str.length <= props.communityConfig.articleTextMax
      ) {
        formRef.value.clearValidate();
      } else {
        if (!editerFirstRender) formRef.value.validateFields("contentText");
      }
      if (editerFirstRender) editerFirstRender = false;
    };

    const topicSearch = debounce(() => {
      state.topicLoading = true;
      getTopicList({ page: 1, keyword: state.topicVal }).then((res) => {
        state.topicLoading = false;
        state.topicList = res.data.list || [];
      });
    }, 500);

    const topicAdd = (type, data) => {
      if (state.formData.topicIds.length >= 5) {
        proxy.$message.warn($t("bbs.addpost.choose_up_topics", [5]));
        // 最多选择5个话题
        return false;
      }
      if (type === "select") {
        if (state.formData.topicIds.includes(data.topicId)) {
          proxy.$message.warn($t("bbs.addpost.this_topic_is_selected"));
          // 已选择此话题
          return false;
        }
        state.formData.topicStrs.push(data.topicName);
        state.formData.topicIds.push(data.topicId);
        state.topicVal = "";
        topicSearch();
      } else if (type === "enter") {
        if (state.topicVal.trim().length) {
          if (state.topicVal.trim().length <= 1) {
            proxy.$message.warn($t("bbs.addpost.less_word_topic", [2]));
            // 话题不得少于2个字
            return false;
          }
          postTopic({
            topicName: state.topicVal,
          }).then((res) => {
            if (res.ret === 0) {
              state.formData.topicStrs.push(state.topicVal);
              state.formData.topicIds.push(res.data);
              state.topicVal = "";
              topicSearch();
            }
          });
        }
      }
    };

    const topicDel = (index) => {
      state.formData.topicStrs.splice(index, 1);
      state.formData.topicIds.splice(index, 1);
    };

    const memberSearch = debounce(() => {
      if (state.memberVal.trim() == "") {
        state.memberList = JSON.parse(JSON.stringify(state.initMemberList));
        return false;
      }
      state.memberLoading = true;
      if (state.platform == 26 || state.platform == 31) {
        let searchAjax = wxworkSearch;
        if (state.platform == 31) searchAjax = dingSearch;
        searchAjax(state.memberVal).then((res) => {
          getAllMember({
            page: 1,
            pageSize: 10,
            keyword: state.memberVal,
            accounts: res.data.users || [],
          }).then((res) => {
            state.memberLoading = false;
            state.memberList = res.data.list || [];
          });
        });
      } else {
        getAllMember({ page: 1, pageSize: 10, keyword: state.memberVal }).then(
          (res) => {
            state.memberLoading = false;
            state.memberList = res.data.list || [];
          }
        );
      }
    }, 500);

    const memberAdd = (data) => {
      if (state.formData.remindUids.length >= 10) {
        proxy.$message.warn($t("bbs.select_most_people", [10]));
        // 最多选择10个人员
        return false;
      }
      if (state.formData.remindUids.includes(data.userId)) {
        proxy.$message.warn($t("bbs.person_is_select"));
        // 已选择此人员
        return false;
      }
      state.formData.remindMembers.push(data);
      state.formData.remindUids.push(data.userId);
      state.memberVal = "";
    };

    const memberDel = (index) => {
      state.formData.remindMembers.splice(index, 1);
      state.formData.remindUids.splice(index, 1);
    };

    const handleOk = (type) => {
      formRef.value.validate().then(() => {
        let formData = JSON.parse(JSON.stringify(state.formData));
        // if (!formData.coverImgs.length) {
        //   proxy.$message.warn("请上传封面");
        //   return false;
        // }
        formData.isAnonymous = formData.isAnonymous ? 1 : 2;
        formData.status = type;
        state.loading = true;
        postSubject(formData).then((res) => {
          const msg = formData.subjectId
            ? $t("CM_Success")
            : $t("bbs.addpost.published_successfully");
          state.loading = false;
          if (res.ret === 0) {
            proxy.$message.success(`${msg}`);
            emit("handleOk");
            state.visible = false;
          }
        });
      });
    };

    const handleCancel = () => {
      let d = state.formData;
      let tipsFlag =
        (d.groupId === "" || d.groupId === props.groupId) &&
        d.title === "" &&
        d.content === "" &&
        d.coverImgs.length === 0 &&
        d.topicIds.length === 0 &&
        d.remindUids.length === 0;
      if (!tipsFlag) {
        Modal.confirm({
          title: () => $t("CM_Tip"),
          // 提示
          icon: () => createVNode(ExclamationCircleOutlined),
          content: $t("bbs.confirm_cancel_tip"),
          // 当前操作尚未保存，是否确认取消？
          onOk() {
            formRef.value.resetFields();
            state.visible = false;
          },
        });
      } else {
        formRef.value.resetFields();
        state.visible = false;
      }
    };

    return {
      ...toRefs(state),
      openModal,
      groupChange,
      uploadInput,
      cropperRef,
      selectFile,
      upImgEnd,
      getWangEditorValue,
      getWangEditorText,
      topicSearch,
      topicAdd,
      topicDel,
      memberSearch,
      memberAdd,
      memberDel,
      formRef,
      handleOk,
      handleCancel,
    };
  },
};
</script>

<style lang="less" scoped>
.custom-footer {
  .mixinWrap(880px);
  .mixinFlex(space-between; center);
}
.post-form {
  .mixinWrap(880px);
  .flex {
    .mixinFlex(space-between; center);
    .cover {
      width: 312px;
      height: 180px;
      background-color: #f4f4f4;
      .mixinImgWrap(312px; 180px);
      .text {
        line-height: 180px;
        font-size: 30px;
        text-align: center;
        letter-spacing: 5px;
        color: #999;
      }
      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        font-size: 0;
        opacity: 0;
        border: none;
      }
    }
    .cover-tips {
      font-size: 12px;
      color: #c4c4c4;
      margin: 2px 0 0 0;
    }
  }
  .editor-tips {
    height: 0;
    position: relative;
    &-num {
      position: absolute;
      right: 0;
      top: 0;
      color: rgba(0, 0, 0, 0.45);
      .red {
        color: red;
      }
    }
  }
  .tips {
    font-size: 14px;
    line-height: 32px;
    color: #c4c4c4;
    padding-left: 28px;
  }
  .tags {
    margin-top: 8px;
    ::v-deep(.ant-tag) {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.65);
      .anticon-close {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
}
::v-deep(#contentText) {
  &:hover {
    .w-e-toolbar {
      border-top-color: @color-theme !important;
      border-right-color: @color-theme !important;
      border-left-color: @color-theme !important;
    }
    .w-e-text-container {
      border-bottom-color: @color-theme !important;
      border-right-color: @color-theme !important;
      border-left-color: @color-theme !important;
    }
  }
}
::v-deep(.w-e-text-container) {
  i {
    font-style: italic;
  }
}
</style>
